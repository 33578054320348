import type { AppProps } from 'next/app';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import { Notifications, notify } from '@corellium/metal';
import { composeWrappers } from '@corellium/metal/utils';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

import 'styles/tailwind.css';
import '@corellium/metal/metal.css';
import 'styles/globals.css';
import { Layout } from 'components/Layout';
import useUser from 'hooks/useUser';
import { handleError } from 'utils/handleError';
import { IntercomProvider } from 'react-use-intercom';
import debounce from 'lodash.debounce';
import { ERROR_TITLES, ERROR_DESCRIPTIONS } from 'types/globals';

const debouncedNotify = debounce(notify, 300);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      debouncedNotify({
        title: (query?.meta?.errorTitle as string) ?? ERROR_TITLES.DEFAULT,
        description: error.message ?? ERROR_DESCRIPTIONS.DEFAULT,
        variant: 'error',
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => {
      debouncedNotify({
        title: (mutation?.meta?.errorTitle as string) ?? ERROR_TITLES.DEFAULT,
        description:
          error instanceof Error
            ? error.message
            : 'Something went wrong' ?? ERROR_DESCRIPTIONS.DEFAULT,
        variant: 'error',
      });
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Root: FC<AppProps> = ({ Component, pageProps, router }) => {
  const [isMounted, setIsMounted] = useState(false);
  const isFullscreen =
    [
      '/login',
      '/_error',
      '/404',
      '/500',
      '/logout',
      '/reauthenticate',
    ].includes(router.pathname) || router.pathname === '/';
  const isPublicRoute =
    ['/login', '/_error', '/404', '/500'].includes(router.pathname) ||
    router.pathname === '/';
  const { user, reauthenticate } = useUser();

  useEffect(() => {
    setIsMounted(true);

    if (!user?.token) {
      return undefined;
    }

    const interval = setInterval(() => {
      reauthenticate().catch(handleError);
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [user?.token, reauthenticate]);

  useEffect(() => {
    if (!isPublicRoute && !user?.token) {
      router.push('/login').catch(handleError);
    }
  }, [user?.token, router, isPublicRoute]);

  if (!isMounted) {
    return null;
  }

  return (
    <Layout
      isFullscreen={isFullscreen}
      isPublicRoute={isPublicRoute}
      isAuthenticated={Boolean(user?.token)}
    >
      <Component {...pageProps} />
    </Layout>
  );
};

const RootProvider = composeWrappers([
  ({ children }) => (
    <IntercomProvider
      autoBoot={false}
      appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? ''}
    >
      {children}
    </IntercomProvider>
  ),
  ({ children }) => (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  ),
] as FC<PropsWithChildren>[]) as FC<PropsWithChildren>;

const App: FC<AppProps> = (props) => (
  <>
    <Head>
      <title>Corellium On-Site Dashboard</title>
      <meta name="robots" content="noindex" />
    </Head>
    <RootProvider>
      <Root {...props} />
      <Notifications position="bottom-right" />
    </RootProvider>
  </>
);

export default App;
