import { Logo } from '@corellium/metal';
import Link from 'next/link';
import type { FC } from 'react';

const AppLogo: FC = () => (
  <Link href="/" className="flex text-gray-900">
    <Logo type="symbol" theme="dark" />
  </Link>
);

export default AppLogo;
